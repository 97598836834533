import { render, staticRenderFns } from "./organization.vue?vue&type=template&id=ab952842&scoped=true"
import script from "./organization?vue&type=script&lang=js&external"
export * from "./organization?vue&type=script&lang=js&external"
import style0 from "./organization.vue?vue&type=style&index=0&id=ab952842&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab952842",
  null
  
)

export default component.exports