import { render, staticRenderFns } from "./roleList.vue?vue&type=template&id=af441f8e&scoped=true"
import script from "./roleList?vue&type=script&lang=js&external"
export * from "./roleList?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af441f8e",
  null
  
)

export default component.exports